import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import {
	CHeader,CHeaderBrand,CHeaderNav,CHeaderToggler,CHeaderDivider,
	CFooter,
	CRow,
	CCol,
	CContainer,
	CForm,CFormInput,CInputGroup,CButton,CInputGroupText,
	CCard,
	CCardHeader,
	CCardBody,
	CCardFooter,
	CImage,
	CLink,
	CTooltip,
	CSpinner,
	CDropdown,CDropdownToggle,CDropdownMenu,CDropdownItem,CDropdownDivider,
} from '@coreui/react';
import CIcon from '@coreui/icons-react'
import Resizer from "react-image-file-resizer";
import ImageGallery from 'react-image-gallery';
import { isMobile } from "react-device-detect";
import {
	MyHeaderProfile,
	MyButton,
	MyDialogform,
	MyDialogview,
	MyDialogviewfullscreen,
	MyDialogpasien,
	MyPagination,
} from '../../components/index'
import { Konfirm } from '../../helpers/onConfirm'
import { UFunc } from '../../helpers/functions'

const pjson 		= require('../../../package.json')
const Fotopasien 	= (props) => {
	const {
		setLoading,showToast,prosesExpired,setSessionaktif 
	} = props;
	const { tabel_id } 				= useParams(); 

	//--DOM--/
	const uNavigate 				= useNavigate();
	const uDispatch 				= useDispatch();
	//--END DOM--/

	const uBahasaObj  				= useSelector(state => state.listBahasa);
	const uActiveroute  			= useSelector(state => state.activeRoute);
	const uSettingObj 				= useSelector(state => state.gListUserSetting);
	const uIsScrollBottom			= useSelector(state => state.gIsScrollBottom);
	const uEfekapp					= useSelector(state => state.effectApp);
	const uUrlbefore 				= useSelector(state => state.gUrlbefore);

	const [uEfekview,setEfekview] 	= React.useState(false);
	const uMaxData					= isMobile ? 10 : 20;
	const uKeywordobj				= JSON.parse(localStorage.getItem("listkeyword")) || {};
	const uTokenObj					= JSON.parse(localStorage.getItem("token")||"{}");
	const uIsHapusallow				= (uTokenObj.userhak==="FREEROLE"||uTokenObj.userhak==="ADMIN");
	const [uHtml500msg,setHtml500msg]	= React.useState(); 
	const [uTimeelapse,setTimeelapse]	= React.useState(0); 
	const [uISLoadingawal,setLoadingawal]= React.useState(false)
	const [uFirstload,setFirstload]		= React.useState("YA")
	const uDatacabangArr					= JSON.parse(localStorage.getItem("cabanglist")||"[]")

	const uPasienID 						= parseInt(tabel_id || "0")
	const uCabangID							= parseInt(uTokenObj.user_cabangid||"0");
	const [uDatapasienObj,setDatapasienObj]	= React.useState({})
	const [uDatatabelArr,setDatatabelArr]	= React.useState([])
	const [uPageActive,setPageActive]		= React.useState(1);
	const [uJmlData,setJmlData]				= React.useState(0);
	const [uJmlHal,setJmlHal]				= React.useState(1);
	const [uIDselected,setIDselected]		= React.useState(0);

	const [uInpKeyword,setInpKeyword]		= React.useState("")
	const [uKeywordloaded,setKeywordloaded]	= React.useState("")

	//--DLG_FORM--/
	const [uDlgformObj,setDlgformObj]		= React.useState({})
	const [uISDlgformshow,setDlgformshow]	= React.useState(false)
	const refFileImg 						= React.useRef();
	//--END DLG_FORM--/

	//--DLG_PASIEN--/
	const [uISDlgpasienshow,setDlgpasienshow]= React.useState(false)
	const [uDlgPasienID,setDlgPasienID]		= React.useState(uPasienID)
	//--END DLG_PASIEN--/

	//--DLG_VIEW--/
	const [uISDlgviewshow,setDlgviewshow]	= React.useState(false)
	const [uDlgviewObj,setDlgviewObj]		= React.useState({})
	//--END DLG_VIEW--/

	//--DLG_VIEWFS--/
	const [uISDlgviewFSshow,setDlgviewFSshow]= React.useState(false)
	const [uDlgviewFSObj,setDlgviewFSObj]	= React.useState({})
	//--END DLG_VIEWFS--/

	//---HANDEL--/
	const hdlKlikBack = () => {
		if(uUrlbefore === "") { uNavigate(-1); return }
		uNavigate(uUrlbefore,{replace:true})
	}
	const hdlKlikReset = () => {
		if(uHtml500msg) { apiLoaddata(); return }

		hdlKlikCarifoto();
	}
	const hdlKlikCarifoto = () => {
		setDatatabelArr([]);
		setJmlHal(1);
		setJmlData(0);
		setPageActive(1);
		setIDselected(0);
		setFirstload("YA");

		apiLoadfoto();
	}
	const hdlKlikTambahfoto = () => {
		setIDselected(0);
		uDlgformObj.header 		= "Input Foto BARU";
		uDlgformObj.tgl_foto 	= UFunc.DbDate();
		uDlgformObj.tgl_input 	= UFunc.WaktuAngka();
		uDlgformObj.user_input 	= UFunc.Usercaptionfilter(uTokenObj.usercaption);

		setDlgformshow(true);
	}
	const hdlKlikFotoadd = () => {
		
		refFileImg.current.click(); 
	}
	const hdlChangeFotofake = (_EV) => {
		const fileUploaded 	= _EV.target.files[0];
		//console.log("(MyDialogfoto - hdlChangeFotofake) fileUploaded => "+(fileUploaded));

		const allowedTypes = ["image/jpeg", "image/png", "image/gif"];
		if (!allowedTypes.includes(fileUploaded?.type)) {
			_EV.target.value = '';
			showToast("Only JPEG, PNG, and GIF images are allowed.");
			return;
		}

        const vFilesize	= parseFloat((fileUploaded.size / 1024)) || 0;//--DALAM_KB

        /*console.log('originalFile instanceof Blob', fileUploaded instanceof Blob); // true
        console.log(`originalFile size ${fileUploaded.size / 1024 / 1024} MB`);
        console.log('originalFile height',fileUploaded.height);
        console.log('originalFile type',fileUploaded.type);
        console.log("vFilesize size "+vFilesize+" KB");
        console.log('Resizer',Resizer);//-*/

        if(vFilesize > 615) {
        	const vFiletype	= (fileUploaded.type||"jpeg").
        		replace(/^image\//, "").toUpperCase();

        	Resizer.imageFileResizer(
        		fileUploaded,
        		1300,
        		800,
        		vFiletype,
        		90,
        		0,
        		(resizedImage) => {
        			//console.log("vFiletype "+(new Date()),vFiletype);
					_EV.target.value = '';
					uDlgformObj.image_src = resizedImage;
					setEfekview(!uEfekview);
        		},
        		"base64"
        	);
			return;
        }

		UFunc.getBase64(fileUploaded).then(result => {
			const fileBase64	= result;
			//setFotobase64(fileBase64)
			_EV.target.value = ''

			uDlgformObj.image_src = fileBase64;
			setEfekview(!uEfekview);
		}).catch(err => {
			_EV.target.value = '';
			showToast(err,"ERROR");
		});
	}
	const hdlKlikDaftarantri = () => {

		uNavigate("/subpasien/pasien/daftarantri/"+uPasienID);
	}
	const hdlKlikRekammedis = () => {

		uNavigate("/subpasien/pasien/rekammedis/"+uPasienID);
	}
	const hdlKlikDetilpasien = () => {

		setDlgpasienshow(true);
	}
	const hdlKlikPreviewall = () => {
		if(uDatatabelArr.length <= 0) return;

		uDlgviewFSObj.header = "Gallery Foto Pasien";
		setDlgviewFSshow(true);
	}
	const hdlKlikPreviewone = (_IDTABEL) => {
		_IDTABEL = parseInt(_IDTABEL)||0; if(_IDTABEL <= 0) return;

		const vArridx = UFunc.getObjectIndeks(uDatatabelArr,_IDTABEL);
		setIDselected(_IDTABEL);

		uDlgviewObj.header 		= "View Foto: "+UFunc.TglAngka(uDatatabelArr[vArridx].tgl_foto);
		uDlgviewObj.tgl_foto 	= uDatatabelArr[vArridx].tgl_foto;
		uDlgviewObj.tgl_input 	= uDatatabelArr[vArridx].tgl_input;
		uDlgviewObj.user_input 	= UFunc.Usercaptionfilter(uDatatabelArr[vArridx].user_input,2);
		uDlgviewObj.elemen_gigi	= uDatatabelArr[vArridx].elemen_gigi;
		uDlgviewObj.tahapan_perawatan 	= uDatatabelArr[vArridx].tahapan_perawatan;
		uDlgviewObj.foto_url 	= uDatatabelArr[vArridx].foto_url;
		uDlgviewObj.id_cabang 	= uDatatabelArr[vArridx].id_cabang;
		uDlgviewObj.tabelid 	= uDatatabelArr[vArridx].id;

		setDlgviewshow(true);
	}
	const hdlKlikEditfoto = (_IDTABEL) => {
		_IDTABEL = parseInt(_IDTABEL)||0; if(_IDTABEL <= 0) return;

		const vArridx = UFunc.getObjectIndeks(uDatatabelArr,_IDTABEL);
		setIDselected(_IDTABEL);

		uDlgformObj.header 		= "Edit Data Foto: "+UFunc.TglAngka(uDatatabelArr[vArridx].tgl_foto);
		uDlgformObj.tgl_foto 	= uDatatabelArr[vArridx].tgl_foto;
		uDlgformObj.tgl_input 	= UFunc.WaktuAngka(uDatatabelArr[vArridx].tgl_input);
		uDlgformObj.user_input 	= UFunc.Usercaptionfilter(uDatatabelArr[vArridx].user_input,2);
		uDlgformObj.elemen_gigi	= uDatatabelArr[vArridx].elemen_gigi;
		uDlgformObj.tahapan_perawatan 	= uDatatabelArr[vArridx].tahapan_perawatan;
		uDlgformObj.image_src 	= uDatatabelArr[vArridx].foto_thumb;
		uDlgformObj.tabelid 	= uDatatabelArr[vArridx].id;

		setDlgformshow(true);
	}
	const hdlKlikHapusfoto = async(_IDTABEL) => {
		_IDTABEL = parseInt(_IDTABEL)||0; if(_IDTABEL <= 0) return;

		const vArridx = UFunc.getObjectIndeks(uDatatabelArr,_IDTABEL);
		setIDselected(_IDTABEL);
		const vCaptionupper	= (UFunc.TglAngka(uDatatabelArr[vArridx].tgl_foto)+" :: "+
			UFunc.Usercaptionfilter(uDatatabelArr[vArridx].elemen_gigi,3)+
		"").toUpperCase();

		if(await Konfirm("Hapus Foto: <b>"+vCaptionupper+"</b>.."+
			"<br/><i>Proses Hapus Hanya Dapat Dilakukan SEKALI, Tidak Dapat DIULANG maupun DIBATALKAN..</i>"+
			"<br/><br/>Apakah Anda Yakin ?"+
			"")) apiProseshapus(_IDTABEL);
	}
	const hdlKlikDlgformsimpan = () => {
		let vElfocus	= document.getElementById("inptglfoto");
		if((uDlgformObj.tgl_foto||"")==="") {
			showToast("Tanggal Pengambilan Foto Tidak Valid !");
			vElfocus && vElfocus.focus(); return;
		}
		const vTglfotoDT	= UFunc.toDate(uDlgformObj.tgl_foto);
		if(UFunc.DateIsBeyond(vTglfotoDT)) {
			showToast("Tanggal Pengambilan Foto Melebihi Tanggal Hari Ini !");
			vElfocus && vElfocus.focus(); return;
		}

		vElfocus	= document.getElementById("inpelemengigi");
		if((uDlgformObj.elemen_gigi||"").trim() ==="") {
			showToast("Elemen Gigi Harus Diisi !");
			vElfocus && vElfocus.focus(); return;
		}
		if((uDlgformObj.image_src||"")==="") {
			showToast("Foto yang Akan Diupload Tidak Valid !");
			return;
		}

		apiProsessimpan();
	}
	//---END HANDEL--/

	//---INIT_PROCESS--/
	//---END INIT_PROCESS--/

	//---CONTENT--/
	const contentHeadersub = () => {
		if(uISLoadingawal) return (
			<div className="mx-auto text-primary classblinking">
			Loading...
			</div>
		)

		if(uHtml500msg) return (
		<>
		<span className="me-1 d-none d-lg-block">Error Page 500</span>
		<CLink onClick={()=>hdlKlikReset()} className="classcursorpointer classikon classikonreset"/>
		</>
		)//>

		return (
		<>
		<div className="">
		<span className="text-info fw-bolder d-none d-md-inline-block">{uDatapasienObj.pasien_caption}</span>
		<span className="text-info d-md-none">{UFunc.Usercaptionfilter(uDatapasienObj.pasien_caption,3)}</span>
		<span className="ms-1">&middot; [<b>{uJmlData}</b>]</span>
		</div>

		<div className="">
			<CTooltip className="d-none d-lg-block" content="--Input Foto BARU">
			<CLink onClick={()=>hdlKlikTambahfoto()} className="classikon classikontambah classcursorpointer"/>
			</CTooltip>

			<span className="d-none d-md-inline-block">
			<CTooltip className="d-none d-lg-block" content="--Refresh Tampilan">
			<CLink onClick={()=>hdlKlikReset()} className="classikontabel classikonreset classcursorpointer"/>
			</CTooltip>

			<CTooltip className="d-none d-lg-block" content="--Gallery Foto">
			<CLink onClick={()=>hdlKlikPreviewall()} className="classcursorpointer">
				<CIcon icon="cilWallpaper" className="classikonbox me-2" height={25}/>
			</CLink>
			</CTooltip>
			</span>

			<CDropdown>
			<CDropdownToggle color="transparent" caret={false} className="p-0 rounded">
				<CIcon icon="cilChevronBottom" className="classikonbox mx-0" height={30}/>
			</CDropdownToggle>

			<CDropdownMenu className="px-0">
				<CDropdownItem onClick={()=>hdlKlikReset()} className="classcursorpointer d-md-none">
				<CIcon className="classikontabel classikonreset mx-0 me-2" height={25}/>
				Refresh Tampilan
				</CDropdownItem>

				<CDropdownItem onClick={()=>hdlKlikPreviewall()} className="classcursorpointer d-md-none">
				<CIcon icon="cilWallpaper" className="classikonbox mx-0 me-2" height={25}/>
				Gallery Foto
				</CDropdownItem>

				<CDropdownDivider className="d-md-none"/>

				{uTokenObj.userhak !== "DOKTER" && (
				<CDropdownItem onClick={()=>hdlKlikDaftarantri()} className="classcursorpointer">
				<CImage src={pjson.homepage+"api/images/icon_booking.png"} className="align-middle me-2" height={25}/>
				Reservasi Pasien
				</CDropdownItem>
				)}

				<CDropdownItem onClick={()=>hdlKlikRekammedis()} className="classcursorpointer">
				<CImage src={pjson.homepage+"api/images/icon_rekammedis.png"} className="align-middle me-2" height={25}/>
				Data Rekam Medis
				</CDropdownItem>

				<CDropdownDivider/>

				<CDropdownItem onClick={()=>hdlKlikDetilpasien()} className="classcursorpointer">
				<CIcon icon="cilFindInPage" className="classikonbox mx-0 me-2" height={25}/>
				Detil Data Pasien
				</CDropdownItem>
			</CDropdownMenu>
			</CDropdown>
		</div>
		</>
		)//>
	}
	const contentDatafoto = () => {
		if(uHtml500msg) return (
			<CCard className="classbgcard mb-3">
			<CCardHeader>Page 500</CCardHeader>
			<CCardBody>
			<div className="m-md-3 py-md-3">{UFunc.renderHTML(uHtml500msg)}</div>
			</CCardBody>
			</CCard>
		)

		const vWidthcard	= isMobile ? (window.innerWidth < 992 ? 350 : 300) : 230;
		const vWidthimg		= 90;

		const vDataviewArr	= uDatatabelArr.filter(vItems => 
			parseInt(vItems.pg||1) === parseInt(uPageActive));

		return (
		<div className="d-flex align-items-stretch justify-content-between justify-content-lg-start" style={{flexWrap:"wrap"}}>
		{vDataviewArr.map((vItems,vKeys)=>{
			const {
				id,tgl_foto,tgl_input,user_input,foto_url,elemen_gigi,
				foto_thumb,
				tahapan_perawatan,is_processing
			} = vItems;

			//console.log("foto_thumb => "+foto_thumb);
			return (
			<CCard style={{minWidth:vWidthcard,width:"15%"}} className="classbgcard mb-3 mx-1" 
				key={vKeys}>
			<CCardHeader className="classfontsmaller py-0 pt-1">
			{UFunc.HariAngka(tgl_foto)}
			</CCardHeader>
			<CCardBody className={"d-flex classfontsmaller p-1 py-2"+(parseInt(uIDselected)===parseInt(id) ? " classrowselect" : "")}>
				<div>
				<CImage src={foto_thumb} 
					onClick={()=>hdlKlikPreviewone(id)}
					className="classcursorpointer"
					thumbnail={true}
					width={vWidthimg}
					height={vWidthimg}/>
				</div>
				<div className="ms-2 d-block" style={{width:"calc(100% - "+vWidthimg+"px)"}}>
					<div className="text-capitalize fw-bolder">
						{UFunc.Usercaptionfilter((elemen_gigi||"ELMNT"),3)}
					</div>
					
					<small className="text-danger">[
						{UFunc.WaktuAngka(tgl_input)}
						<span className="mx-1 d-lg-none">&middot;</span>
						<span className="d-none d-lg-inline"> </span>
						{UFunc.Usercaptionfilter(user_input,1)}
					]</small>
					
					<div className="mt-2 mb-1 classborderbottom"/>
					
					<CLink onClick={()=>hdlKlikEditfoto(id)} className="classikontabel classikonedit classcursorpointer"/>
					
					{uIsHapusallow && (
					<>
					<span className="me-1">&middot;</span>
					<CLink onClick={()=>hdlKlikHapusfoto(id)} className="classikontabel classikonhapus classcursorpointer"/>
					</>
					)}

					<div className="d-none">{foto_thumb}</div>
				</div>
			</CCardBody>
			</CCard>
			)
		})}
		</div>
		)//>
	}
	const contentDlgform = () => {
		if(!uISDlgformshow) return (<></>)//>

		return (
		<CForm>
		<CRow className="my-2">
			<CCol className="text-center">
			{(uDlgformObj.image_src||"")==="" ? (
				<CTooltip content="--Tambah FOTO" className="d-none d-lg-block">
				<CLink onClick={()=>hdlKlikFotoadd()} 
					className="classboxthumbs classcursorpointer"
					style={{height:90,width:90,}}>
					<CIcon icon="cibAddthis" className="text-warning" height={35}/>
				</CLink>
				</CTooltip>
			) : (
			<>
				{parseInt(uDlgformObj.tabelid||"0") <= 0 ? (
				<CLink onClick={()=>{hdlKlikFotoadd()}} 
					className="classboxthumbs classcursorpointer my-3">
					<div style={{
							backgroundSize:"cover",
							backgroundColor:"transparent",
							backgroundImage:"url("+uDlgformObj.image_src+")",
							backgroundPosition:"center center",
							backgroundRepeat:"no-repeat",
							height:100,width:100
						}}
						className="rounded-1">
						<div 
							style={{ height:100,width:100 }}
							className="classeditthumbs rounded-1"/>
					</div>
				</CLink>
				) : (
				<CImage src={uDlgformObj.image_src} thumbnail width={100}/>
				)}
			</>
			)}
			<input
				type="file"
				accept="image/png, image/jpeg, image/gif, capture=camera"
				ref={refFileImg}
				onChange={(e)=>hdlChangeFotofake(e)}
				className="d-none"
				id="inpfileimg"/>
			</CCol>
		</CRow>

		<div className="my-3 classborderbottom"/>

		<CRow className="my-2">
			<CCol xs="4" className="text-start text-md-end">
				<span className="d-none d-lg-block">Tgl Pengambilan Foto</span> 
				<small className="d-lg-none">Tgl Foto</small> 
			</CCol>
			<CCol xs="8" lg="4" className="text-end text-md-start">
			<CFormInput size="sm" type="date"
				value={uDlgformObj.tgl_foto}
				onChange={(e)=>{uDlgformObj.tgl_foto=e.target.value;setEfekview(!uEfekview)}}
				id="inptglfoto"/>
			</CCol>
		</CRow>

		<CRow className="my-2">
			<CCol xs="4" className="text-start text-md-end">
				<span className="d-none d-md-block">Elemen Gigi</span> 
				<small className="d-md-none">Elemen Gigi</small> 
			</CCol>
			<CCol xs="8" lg="4" className="text-end text-md-start">
			<CFormInput size="sm" maxLength={50}
				value={uDlgformObj.elemen_gigi}
				onChange={(e)=>{uDlgformObj.elemen_gigi=e.target.value;setEfekview(!uEfekview)}}
				id="inpelemengigi"/>
			</CCol>
		</CRow>

		<CRow className="my-2">
			<CCol xs="4" className="text-start text-md-end">
				<span className="d-none d-md-block">Tahap Perawatan</span> 
				<small className="d-md-none">Tahapan</small> 
			</CCol>
			<CCol className="text-end text-md-start">
			<CFormInput size="sm" maxLength={100}
				value={uDlgformObj.tahapan_perawatan}
				onChange={(e)=>{uDlgformObj.tahapan_perawatan=e.target.value;setEfekview(!uEfekview)}}
				onKeyDown={(e)=>UFunc.hdlKeyDownInput(e,"btnDialogSimpan")}
				id="inptahapperawatan"/>
			</CCol>
		</CRow>

		<CRow className="my-2 text-danger">
			<CCol xs="4" className="text-start text-md-end">
				<span className="d-none d-md-block">Tanggal Input</span> 
				<small className="d-md-none">Tgl Input</small> 
			</CCol>
			<CCol className="text-end text-md-start classfontsmaller">
			<small className="d-md-none">[{uDlgformObj.tgl_input} &middot; {uDlgformObj.user_input}]</small>
			<span className="d-none d-md-block">[{uDlgformObj.tgl_input} &middot; {uDlgformObj.user_input}]</span>
			</CCol>
		</CRow>

		</CForm>
		)//>
	}
	const contentDlgview = () => {
		if(!uISDlgviewshow) return (<></>)//>

		return (
		<>
		<div className="my-2 text-center">
			<CImage src={uDlgviewObj.foto_url} className="mx-auto" fluid width={650}/>
		</div>

		<div className="my-3 classborderbottom"/>

		<CRow className="my-2 align-items-stretch classfontsmaller">
			<CCol lg="6" className="mb-3">
				<CCard className="h-100">
				<CCardHeader className="py-0 pt-1"><small>Timestamp</small></CCardHeader>
				<CCardBody>
				<div className="d-flex justify-content-between my-1">
					<div>Tgl Foto</div>
					<b className="text-end">{UFunc.TglAngka(uDlgviewObj.tgl_foto)}</b>
				</div>

				<div className="d-flex justify-content-between my-1 text-danger">
					<div>Tgl Input</div>
					<small className="text-end">[
						{UFunc.WaktuAngka(uDlgviewObj.tgl_input)}
						<span className="mx-1">&middot;</span>
						{uDlgviewObj.user_input}
					]</small>
				</div>

				<div className="d-flex justify-content-between my-1">
					<div>Cabang</div>
					<b className="text-primary text-end">{(()=>{
						const vArridx = UFunc.getObjectIndeks(uDatacabangArr,uDlgviewObj.id_cabang);

						return (uDatacabangArr[vArridx]||{}).cabang_nama || "UNDF";
					})()}</b>
				</div>
				</CCardBody>
				</CCard>
			</CCol>
			
			<CCol className="mb-3">
				<CCard className="h-100">
				<CCardHeader className="py-0 pt-1"><small>Keterangan Foto</small></CCardHeader>
				<CCardBody>
				<div className="d-flex justify-content-between my-1">
					<div className="d-none d-md-block">Elemen Gigi</div>
					<small className="d-md-none">Elemen Gigi</small>
					<b className="text-end">{(uDlgviewObj.elemen_gigi || "Elemen")}</b>
				</div>

				<div className="d-flex justify-content-between my-1">
					<div className="d-none d-md-block">Tahapan Perawatan</div>
					<small className="d-md-none">Tahapan Perawatan</small>
					<i className="text-end">{(uDlgviewObj.tahapan_perawatan || "-")}</i>
				</div>
				</CCardBody>
				</CCard>
			</CCol>
		</CRow>
		</>
		)//>
	}
	const contentDlgviewFS = () => {
		if(!uISDlgviewFSshow) return (<></>)//>

		const vFotoviewArr	= uDatatabelArr.map( vItems => {
			return ({
				original: vItems.foto_url,
				originalAlt: vItems.elemen_gigi,
				originalTitle: vItems.elemen_gigi,
				description: vItems.elemen_gigi+(vItems.tahapan_perawatan ? " :: "+vItems.tahapan_perawatan : ""),
				thumbnail: vItems.foto_thumb,
				thumbnailAlt: vItems.elemen_gigi,
				thumbnailTitle: vItems.elemen_gigi,
				thumbnailHeight: 85,
				thumbnailWidth: 85,
			})
		})//-*/

		return (
		<ImageGallery items={vFotoviewArr}/>
		)//>
	}
	//---END CONTENT--/

	//---REST_API--/
	const apiLoaddata = () => {
		setHtml500msg();

		setDatapasienObj({})
		setLoadingawal(true);

		/*//--TESTING_FRONTEND--/
		console.log("(Daftarantri - apiLoadawal) LEWAT ");
		let vTimeout = setTimeout(()=>{
		},2000); return
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_pasienid : uPasienID,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_rekammedis/ld_data";
		//const vURLs	= pjson.homepage+"api/api_pasien/ld_formantrian";

		const vTimeBegin = new Date();
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200){setSessionaktif(false); return response.json();} })
		.then((output_string) => {
			const vTimeEnd = new Date(); const vTimeDiff = vTimeEnd - vTimeBegin; //in ms
			setLoadingawal(false);
			if(output_string.tampil) {
				setDatapasienObj(JSON.parse(output_string.datapasien||"{}"))
				apiLoadfoto();
				return;
			} else if(output_string.info) {
				setHtml500msg(output_string.info);
			} else if(output_string.errors) {
				console.log("(Fotopasien - apiLoaddata) output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				setHtml500msg("<div class='text-danger'>"+vMsg+"</div>");
			} else if(output_string.expired) {
				setLoadingawal(true); prosesExpired(); return;
			}

			if(vTimeDiff > 500) setSessionaktif(true);
			else {
				let vTimeout = setTimeout(()=>{ clearTimeout(vTimeout); setSessionaktif(true);},500)
			}
		})
		.catch((error) =>{
			setLoadingawal(false);
			console.log("(Fotopasien - apiLoaddata) catch-error : "+error);
			setHtml500msg(pjson.mydefault.msg500str.join(" "));
		});
	}
	const apiLoadfoto = (_PAGE) => {
		_PAGE	= parseInt(_PAGE)||0;

		const vKeyword 		= uInpKeyword;
		const vPageload		= parseInt(_PAGE)||1;
		let vDatamainArr;
		if(uDatatabelArr.length >= 10000) {
			vDatamainArr	= uDatatabelArr.filter(vItems=>parseInt(vItems.pg||1) === 1);
		} else
			vDatamainArr	= uDatatabelArr.filter(vItems=>parseInt(vItems.pg||1) !== parseInt(vPageload));
		setLoading(true);

		/*//--TESTING_FRONTEND--/
		console.log("(Fotopasien - apiLoadfoto) LEWAT ");
		let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout);setLoading(false);
		},2000); return
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_keyword : vKeyword,
			send_pasienid: uPasienID,
			send_pg: vPageload,
			send_maxdata: uMaxData,
			send_firstload: (uFirstload==="YA" || _PAGE==0) ? "YA" : "TIDAK",
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_fotopasien/ld_data";
		//const vURLs	= pjson.homepage+"api/api_pasien/ld_formantrian";

		setTimeelapse(0);
		const vTimeBegin = new Date();
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200){setSessionaktif(false); return response.json();} })
		.then((output_string) => {
			const vTimeEnd = new Date(); const vTimeDiff = vTimeEnd - vTimeBegin; //in ms
			setLoading(false);
			if(output_string.tampil) {
				const vTmpArr = JSON.parse(output_string.datafoto||"[]");
				vTmpArr.map(vItems => vItems.pg = vPageload);

				setJmlData(parseInt(output_string.totaldata||uJmlData))
				/*if(vKeyword.length >= 3)
					setJmlData(parseInt(output_string.total_data||uJmlData))
				else setJmlData(vTmpArr.length);//-*/

				setDatatabelArr([...vDatamainArr,...vTmpArr]);

				setKeywordloaded(uInpKeyword);
				setFirstload("TIDAK");

				setTimeelapse(vTimeDiff);
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("(Fotopasien - apiLoadfoto) output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				setLoading(true); prosesExpired(); return;
			}

			if(vTimeDiff > 500) setSessionaktif(true);
			else {
				let vTimeout = setTimeout(()=>{ clearTimeout(vTimeout); setSessionaktif(true);},500)
			}
		})
		.catch((error) =>{
			setLoading(false);
			console.log("(Fotopasien - apiLoadfoto) catch-error : "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	const apiProsessimpan = () => {
		const vTabelID	= parseInt(uDlgformObj.tabelid||"0");
		const vElsimpan	= document.getElementById("btnDialogSimpan");
		vElsimpan && (vElsimpan.disabled = true);
		setLoading(true);

		/*//--TESTING_FRONTEND--/
		console.log("(Fotopasien - apiLoadfoto) LEWAT ");
		let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout);setLoading(false);
		},2000); return
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_pasienid : uPasienID,
			send_datapars : JSON.stringify(uDlgformObj),
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_fotopasien/pr_simpan";

		const vTimeBegin = new Date();
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200){ setSessionaktif(false); return response.json();} })
		.then((output_string) => {
			const vTimeEnd = new Date(); const vTimeDiff = vTimeEnd - vTimeBegin; //in ms
			vElsimpan && (vElsimpan.disabled = false);
			setLoading(false);
			if(output_string.sukses) {
				if(vTabelID <= 0) {
					const vTmpObj	= {
						id: output_string.tabelid,
						id_cabang: uCabangID,
						tgl_foto: (uDlgformObj.tgl_foto||UFunc.DbDate()),
						elemen_gigi: (uDlgformObj.elemen_gigi||"").trim(),
						tahapan_perawatan: (uDlgformObj.tahapan_perawatan||"").trim(),
						foto_thumb: output_string.foto_thumb || uDlgformObj.image_src,
						foto_url: output_string.foto_url || uDlgformObj.image_src,
						tgl_input: UFunc.DbDatetime(),
						user_input: uTokenObj.usercaption,
					}

					setDatatabelArr([vTmpObj,...uDatatabelArr]);

					uDlgformObj.tgl_foto	= UFunc.DbDate();
					uDlgformObj.elemen_gigi	= "";
					uDlgformObj.tahapan_perawatan	= "";
					uDlgformObj.image_src	= undefined;

					showToast(uBahasaObj.pesan_sukses || "Berhasil !","SUKSES");
				} else {
					const vArridx	= UFunc.getObjectIndeks(uDatatabelArr,vTabelID);

					uDatatabelArr[vArridx].elemen_gigi = (uDlgformObj.elemen_gigi||"").trim();
					uDatatabelArr[vArridx].tahapan_perawatan = (uDlgformObj.tahapan_perawatan||"").trim();
					uDatatabelArr[vArridx].tgl_foto = (uDlgformObj.tgl_foto||UFunc.DbDate());

					setDlgformshow(false);
				}
				setEfekview(!uEfekview);
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("(Fotopasien - apiProsessimpan) output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				setLoading(true); prosesExpired(); return
			}

			if(vTimeDiff > 500) setSessionaktif(true);
			else {
				let vTimeout = setTimeout(()=>{ clearTimeout(vTimeout); setSessionaktif(true);},500)
			}
		})
		.catch((error) =>{
			vElsimpan && (vElsimpan.disabled = false);
			setLoading(false);
			console.log("(Fotopasien - apiProsessimpan) catch-error : "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	const apiProseshapus = (_IDTABEL) => {
		_IDTABEL	= parseInt(_IDTABEL)||0;if(_IDTABEL <= 0) return; 
		setLoading(true);

		/*//--TESTING_FRONTEND--/
		console.log("(Fotopasien - apiLoadfoto) LEWAT ");
		let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout);setLoading(false);
		},2000); return
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_pasienid : uPasienID,
			send_tabelid : _IDTABEL,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_fotopasien/pr_hapus";

		const vTimeBegin = new Date();
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200){ setSessionaktif(false); return response.json();} })
		.then((output_string) => {
			const vTimeEnd = new Date(); const vTimeDiff = vTimeEnd - vTimeBegin; //in ms
			setLoading(false);
			if(output_string.sukses) {
				const vArridx = UFunc.getObjectIndeks(uDatatabelArr,_IDTABEL);
				uDatatabelArr.splice(vArridx,1);
				setJmlData(uJmlData-1);
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("(Fotopasien - apiProseshapus) output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				setLoading(true); prosesExpired(); return
			}

			if(vTimeDiff > 500) setSessionaktif(true);
			else {
				let vTimeout = setTimeout(()=>{ clearTimeout(vTimeout); setSessionaktif(true);},500)
			}
		})
		.catch((error) =>{
			setLoading(false);
			console.log("(Fotopasien - apiProseshapus) catch-error : "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	//---END REST_API--/

	//---EFFECT--/
	React.useEffect(()=>{})
	React.useEffect(()=>{
		uDispatch({type: "set", effectApp: !uEfekapp});

		apiLoaddata();

		return () => {
			setDlgformshow(false);setDlgviewshow(false);
			setDatapasienObj({});setDatatabelArr([]);
			setJmlHal(1); setJmlData(0);setFirstload("YA");
			setPageActive(1);setIDselected(0);
		}
	},[])
	React.useEffect(()=>{ 
		if(uFirstload === "YA") return;
    	if(uPageActive <= 0) { setPageActive(1); return }

		const vDataviewArr	= uDatatabelArr.filter(vItems=>parseInt(vItems.pg||1) === parseInt(uPageActive));
		if(vDataviewArr.length > 0) {
			window.scrollTo({ top: 0, behavior: "smooth" });
			return;
		}

		apiLoadfoto(uPageActive);
	},[uPageActive])
	React.useEffect(()=>{
		let vJmlHal	= Math.ceil(uJmlData / uMaxData);
		setJmlHal(vJmlHal);//-*/
	},[uJmlData])
	React.useEffect(()=>{
		if(!uISDlgformshow) { setDlgformObj({}); return }

		let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout);
			const vElfocus = document.getElementById("inptglfoto");

			if(!isMobile) vElfocus && vElfocus.focus();
		},350)
	},[uISDlgformshow])
	React.useEffect(()=>{
		if(!uISDlgviewshow)
			{ setDlgviewObj({}); return }
	},[uISDlgviewshow])
	//---END EFFECT--/

	//console.log("(Fotopasien) uNavigate => "+JSON.stringify(uNavigate))
	if(UFunc.isEmpty(uTokenObj)) { prosesExpired(); return ""; }

	return (
		<>
		<div className="wrapper d-flex flex-column min-vh-100 bg-transparent">
		<CHeader position="sticky" className="mb-3 p-0">
		<CContainer fluid className="classheadertop" style={{minHeight:64}}>
			<CHeaderToggler className="px-0" onClick={() => hdlKlikBack() }>
			<CIcon icon="cilArrowCircleLeft" className="me-2" height={30}/>
			</CHeaderToggler>
			
			<CHeaderBrand className="me-auto text-normal">
				<small className="align-top fw-bold">Foto Pasien</small>
			</CHeaderBrand>

			<CHeaderNav>
				<MyHeaderProfile {...props}/>
			</CHeaderNav>
		</CContainer>

		<CContainer fluid className="bg-white p-2 px-lg-4" style={{minHeight:52}}>
			{contentHeadersub()}
		</CContainer>
		</CHeader>

		<div className="body flex-grow-1 px-2 px-lg-5 mb-3">
		<CContainer fluid className="px-0">
			<div className="d-flex justify-content-center">
			{contentDatafoto()}
			</div>

			{(uJmlData > uMaxData) && (
			<MyPagination 
				activePage={uPageActive}
				pages={uJmlHal}
				onActivePageChange={(i)=>setPageActive(i)}
				align="start"/> 
			)}
		</CContainer>
		</div>
		</div>

		<MyDialogform
			options={{centered:true,size:"lg"}}
			onSimpan={hdlKlikDlgformsimpan}
			show={uISDlgformshow} 
			dialogHeader={uDlgformObj.header}
			toggle={()=>setDlgformshow(false)}
			renderContent={contentDlgform()}/>

		<MyDialogview
			options={{centered:true,size:"lg"}}
			show={uISDlgviewshow} 
			dialogHeader={uDlgviewObj.header}
			toggle={()=>setDlgviewshow(false)}
			renderContent={contentDlgview()}/>

		<MyDialogviewfullscreen 
			options={{bgColor:"black",init_bgimage:"TIDAK"}}
			show={uISDlgviewFSshow} 
			dialogHeader={uDlgviewFSObj.header}
			toggle={()=>setDlgviewFSshow(false)}
			renderContent={contentDlgviewFS()}
			{...props}/>

		<MyDialogpasien show={uISDlgpasienshow}
			pasienID={uDlgPasienID}
			toggle={()=>setDlgpasienshow(false)}
			{...props}/>
		</>
	)//>
}	

export default Fotopasien