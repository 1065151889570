import React from 'react';
import { useSelector } from 'react-redux';
import {
} from '@coreui/react';
//import PropTypes from 'prop-types';
//import { MyButton } from './index';
//import { UFunc } from "../helpers/functions";

const pjson 	= require('../../package.json')
const MySessionextender = (props) => {
	const { 
		active,showToast,setSessionaktif, prosesExpired,
		...rest 
	} = props;

	const uTokenObj					= JSON.parse(localStorage.getItem("token")||"{}");
	//const [uEfekview,setEfekview] 	= React.useState(false);
	//const uBahasaObj	= useSelector(state => state.listBahasa);

	//--COUNTERSESSIONLOKAL--/
	const uEfeksessionapp	= useSelector(state => state.effectsessionApp) || false;
	const uTimeouttimer		= parseInt(useSelector(state => state.gTimesessionlimit)) || 1800;
	const [uCoundowncounter,setCoundowncounter]	= React.useState(uTimeouttimer);
	const [uCountertimestart,setCountertimestart]= React.useState();
	//--END COUNTERSESSIONLOKAL--/

	const prosesSessioncount = () => {
		if(!uCountertimestart) return;
		//if(uCountertimestart === undefined) return;

		const vTimecekDT	= new Date();
		const vSelisihDTK	= parseInt((vTimecekDT.getTime() - uCountertimestart.getTime()) / 1000 );
		
		//console.log("(App - prosesSessioncount) vSelisihDTK => "+vSelisihDTK);
		const vCountsession	= uTimeouttimer - vSelisihDTK; 
		setCoundowncounter(vCountsession);
	}

	const apiBreaksession = () => {
 		setSessionaktif(false);

		const vDATAS	= JSON.stringify({ send_tokenauth:uTokenObj.userinit });
		const vURLs	= pjson.homepage+"api/api_init/pr_session";

		const vTimeBegin = new Date();
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) { return response.json(); }})
		.then((output_string) => {
			const vTimeEnd = new Date(); const vTimeDiff = vTimeEnd - vTimeBegin; //in ms
			if(output_string.sukses) {
				//setSessionaktif(true);
				if(vTimeDiff > 500) setSessionaktif(true);
				else {
					let vTimeout = setTimeout(()=>{ clearTimeout(vTimeout); setSessionaktif(true);},500)
				}
			} else if(output_string.expired) {
				prosesExpired();
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("(MySessionextender - apiBreaksession) output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			}
		})
		.catch((error) =>{
			console.log("(MySessionextender - apiBreaksession) catch-error : "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}

	React.useEffect(()=>{
	},[]);
	React.useEffect(()=>{
		if(!active) {
			setCountertimestart();
			return;
		}
		if(!uEfeksessionapp) {
			setCoundowncounter(uTimeouttimer);
			setCountertimestart();
			return;
		}

		setCountertimestart(new Date());
	},[uEfeksessionapp]);
	React.useEffect(() => {
		//console.log("(App - [uCountertimestart]) uCountertimestart => "+uCountertimestart);
		if(!uCountertimestart) return;
		
		//prosesSessioncount(); return;

		let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout);
			prosesSessioncount();
		},1000)
	},[uCountertimestart]);
	React.useEffect(() => {
		if(!uEfeksessionapp) return;
		
		//--PROSES_AUTO_SESSION--/
		if((uCoundowncounter <= (uTimeouttimer / 2)) 
			) {
			//console.log("(Periksa - [uCoundowncounter]) uCoundowncounter => "+uCoundowncounter);
			apiBreaksession();
			return;
		}
		//--END PROSES_AUTO_SESSION--/

		let vTimeout = setTimeout(function(){
			clearTimeout(vTimeout);
			prosesSessioncount();
		},1000); 
	},[uCoundowncounter]);//-*/

	//console.log("(MySessionextender) options.centered = "+options.centered);
	return (
		<>
		<div style={{zIndex:2000}} className="d-none position-fixed bottom-0 end-0 p-2 text-primary">[{uCoundowncounter}]</div>
		</>
	)//->
}

export default MySessionextender;
